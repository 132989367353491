import React from 'react'
import { Row, Col, Divider } from 'antd'
import Wrapper, { Label, Span, FootLabel, FooterDiv } from './Footer.style'
import useTranslation from 'next-translate/useTranslation'
import {
  FaCcMastercard,
  FaCcVisa,
  FaInstagram,
  FaTwitter,
  FaFacebook,
  FaWhatsapp,
} from 'react-icons/fa'
import Link from 'next/link'
import { useRouter } from 'next/router'

const Footer = () => {
  const { t, lang } = useTranslation()
  const router = useRouter()
  return (
    <Wrapper>
      <Row className="footer-row-container" style={{ width: '100%' }} justify="center">
        <Col
          style={{
            padding: '52px 0px 10px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
          span={24}
        >
          <Row justify="center" className="footer-container-style">
            <Col style={{ float: 'left', textAlign: 'left' }} xs={24} sm={24} lg={10} md={10}>
              <Row>
                <Col>
                  <Span>{t('common:contact')}</Span>
                  <Row style={{ marginTop: '2%' }} gutter={8}>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                      <Link href="/iletisim">
                        <a>
                          <Label>{t('common:contact')}</Label>
                        </a>
                      </Link>
                    </Col>
                  </Row>
                  <Label>
                    Mustafa Kemal Mah. <br />
                    Dumlupınar Bulvarı <br /> No:266 A/62 <br /> Çankaya / Ankara
                  </Label>
                  <Row style={{ marginTop: '2%' }} gutter={24}>
                    <Col
                      span={6}
                      onClick={() =>
                        window.open('https://api.whatsapp.com/send?phone=905308149934', 'nofollow')
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <FaWhatsapp color="#ffffff" size={20} />
                    </Col>
                    <Col
                      span={6}
                      onClick={() => window.open('https://www.instagram.com/invidza/', 'nofollow')}
                      style={{ cursor: 'pointer' }}
                    >
                      <FaInstagram color="#ffffff" size={20} />
                    </Col>
                    <Col
                      span={6}
                      onClick={() => window.open('https://twitter.com/invidza', 'nofollow')}
                      style={{ cursor: 'pointer' }}
                    >
                      <FaTwitter color="#ffffff" size={20} />
                    </Col>
                    <Col
                      span={6}
                      onClick={() => window.open('https://www.facebook.com/invidza/', 'nofollow')}
                      style={{ cursor: 'pointer' }}
                    >
                      <FaFacebook color="#ffffff" size={20} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col style={{ flex: 1 }} xs={24} lg={8} md={8}>
              <Row justify="flex-start">
                <Col>
                  <Span>{t('common:corporate')}</Span>

                  <Link href={`/p/${t('common:about-slug')}`}>
                    <a>
                      <Label>{t('common:about')}</Label>
                    </a>
                  </Link>
                  <Link href="/yardim-merkezi">
                    <a>
                      <Label>{t('common:user_guide')}</Label>
                    </a>
                  </Link>
                  {/* <Link href={'/faq'}>
                    <a>
                      <Label>{t('common:faq')}</Label>
                    </a>
                  </Link> */}
                  <Link href="/online-davetiye">
                    <a>
                      <Label>{t('common:invitation_templates')}</Label>
                    </a>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={6} md={6}>
              <Row className="join-us-container">
                <Col>
                  <Span>{t('common:join_us')}</Span>

                  <Link href="/pdf/invidza_cozum_ortagi_brosuru.pdf">
                    <a>
                      <Label>{t('common:affiliate_marketing')}</Label>
                    </a>
                  </Link>

                  <Label>{t('common:become_a_designer')}</Label>

                  <Row>
                    <FaCcVisa color="#ffffff" size={34} />
                    <FaCcMastercard color="#ffffff" style={{ marginLeft: '3%' }} size={34} />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Divider style={{ marginBottom: 0, border: '1px solid #ffffff' }} />
        <Col
          style={{
            padding: '10px 0px 10px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
          span={24}
        >
          <Row justify="center" style={{ width: '100%', maxWidth: 1232 }}>
            <Col span={24}>
              <FooterDiv>
                <div style={{ display: 'flex', flex: 0.5 }}>
                  <svg
                    width="123"
                    height="30"
                    viewBox="0 0 123 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M53.1964 5.83612C53.5553 5.49881 54.0061 5.3208 54.5585 5.3208C55.1109 5.3208 55.5617 5.48897 55.9206 5.83612C56.2795 6.18232 56.4546 6.60421 56.4546 7.11952C56.4546 7.64371 56.2795 8.08426 55.9302 8.42162C55.58 8.75893 55.1205 8.93693 54.5585 8.93693C53.9964 8.93693 53.5369 8.76875 53.1867 8.42162C52.8365 8.08426 52.6624 7.64466 52.6624 7.11952C52.6537 6.60421 52.8375 6.17343 53.1964 5.83612ZM52.9575 10.715H56.1615V21.8869H52.9575V10.715Z"
                      fill="white"
                    />
                    <path
                      d="M58.6035 10.714L61.3468 10.5734L61.7621 12.2216C61.8086 12.1744 61.8721 12.1095 61.9457 12.0249C62.0192 11.9403 62.1857 11.7909 62.4333 11.5843C62.6822 11.3779 62.9492 11.191 63.2345 11.0415C63.5198 10.8822 63.8699 10.7415 64.284 10.6108C64.6993 10.4799 65.1317 10.4141 65.554 10.4141C68.0394 10.4141 69.2824 11.9876 69.2824 15.1336V21.886H66.0793V15.583C66.0793 14.0007 65.4805 13.2139 64.2934 13.2139C63.9434 13.2139 63.5846 13.3172 63.2169 13.5326C62.8486 13.7391 62.5728 13.9446 62.3975 14.1502L62.1027 14.4501V21.8948H58.8997V13.8689L58.6035 10.714Z"
                      fill="white"
                    />
                    <path
                      d="M48.2593 8.5735L42.187 13.7444C41.3889 14.4358 41.5891 15.8391 42.393 16.5177L48.2631 21.3582C48.5708 21.6179 48.979 21.2766 48.979 20.7662V9.16157C48.9799 8.64726 48.5669 8.30696 48.2593 8.5735Z"
                      fill="white"
                    />
                    <path
                      d="M48.0505 23.278L38.9754 16.2759L36.5086 18.2241C36.2552 18.4404 35.9088 18.5771 35.6108 18.5948C35.3091 18.6125 35.0043 18.5279 34.7402 18.3352L32.0538 16.3781L17.2992 27.7369C16.1403 28.6289 16.6965 30.2113 18.0741 29.9772L47.6935 24.941C48.3242 24.8348 48.569 23.6802 48.0505 23.278Z"
                      fill="white"
                    />
                    <path
                      d="M47.7422 5.0812L42.7457 4.2315C42.0734 4.11742 41.3836 4.29248 40.8428 4.71535L35.9924 8.50851C35.7447 8.70222 35.3984 8.70322 35.1508 8.50851L26.5178 1.76896C26.1986 1.52015 25.8242 1.35394 25.4266 1.28707L17.9835 0.0233453C16.6108 -0.209731 16.0478 1.35788 17.1941 2.25577L34.7474 16.022C35.2727 16.4341 35.9566 16.4311 36.4664 16.024L37.992 14.8065C38.0452 14.7573 38.1003 14.7131 38.1583 14.6737L48.1041 6.73928C48.6158 6.33115 48.3691 5.18741 47.7422 5.0812Z"
                      fill="white"
                    />
                    <path
                      d="M70.25 10.7139H73.8683L76.1784 16.6696L76.4725 17.5783H76.519L76.7673 16.6696L79.1056 10.7139H82.4469L77.5491 21.8858H75.1925L70.25 10.7139Z"
                      fill="white"
                    />
                    <path
                      d="M83.992 5.83612C84.3509 5.49881 84.8026 5.3208 85.3538 5.3208C85.9062 5.3208 86.358 5.48897 86.7162 5.83612C87.0751 6.18232 87.2504 6.60421 87.2504 7.11952C87.2504 7.64371 87.0762 8.08426 86.7256 8.42162C86.3756 8.75893 85.915 8.93693 85.3538 8.93693C84.7932 8.93693 84.3326 8.76875 83.9826 8.42162C83.632 8.08426 83.4573 7.64466 83.4573 7.11952C83.4491 6.60421 83.6332 6.17343 83.992 5.83612ZM83.7532 10.715H86.9562V21.8869H83.7532V10.715Z"
                      fill="white"
                    />
                    <path
                      d="M96.7562 4.7373H99.9593V19.6365L100.376 21.8689L97.8969 22.0253L97.391 21.0045C96.4427 21.7912 95.3844 22.1934 94.215 22.1934C92.8249 22.1934 91.6196 21.6968 90.5966 20.7045C89.5743 19.7122 89.0684 18.2321 89.0684 16.2564C89.0684 15.3575 89.2066 14.5521 89.4642 13.8401C89.7313 13.128 90.0631 12.5567 90.4584 12.1446C90.8543 11.7227 91.3149 11.3765 91.8302 11.1051C92.3461 10.8337 92.8338 10.6557 93.2938 10.5623C93.7544 10.4688 94.205 10.4216 94.6474 10.4216C94.9974 10.4216 95.6968 10.4875 96.7551 10.6281V4.7373H96.7562ZM96.7562 18.2793V13.25C96.1768 13.0346 95.6144 12.9225 95.0621 12.9225C94.3726 12.9225 93.765 13.1752 93.2214 13.6906C92.6779 14.2059 92.4108 14.9828 92.4108 16.0135C92.4108 17.1002 92.6408 17.9243 93.1014 18.476C93.562 19.0376 94.0962 19.309 94.7032 19.309C95.0709 19.309 95.4215 19.2244 95.7615 19.0562C96.1021 18.888 96.3603 18.7101 96.5262 18.5222L96.7562 18.2793Z"
                      fill="white"
                    />
                    <path
                      d="M102.348 10.7139H111.186L111.461 12.8401L106.168 19.199H111.405V21.8769H102.32L101.924 19.6199L107.171 13.3918H102.348V10.7139Z"
                      fill="white"
                    />
                    <path
                      d="M114.764 13.8624L113.806 11.6714C113.898 11.5592 114.091 11.4275 114.387 11.2495C114.681 11.0813 115.16 10.8935 115.804 10.7066C116.448 10.5099 117.129 10.4165 117.848 10.4165C119.403 10.4165 120.592 10.8472 121.419 11.7185C122.249 12.5899 122.654 13.8163 122.654 15.4173V19.0226L122.93 21.8883L120.186 22.0477L119.819 20.84C118.935 21.7389 117.811 22.1883 116.458 22.1883C115.418 22.1883 114.525 21.8982 113.77 21.317C113.015 20.7367 112.637 19.9126 112.637 18.8358C112.637 16.3349 114.911 15.0898 119.449 15.0898C119.449 13.6668 118.759 12.9449 117.387 12.9449C117.019 12.9449 116.622 13.0196 116.19 13.1701C115.757 13.3196 115.426 13.4514 115.205 13.5724C114.984 13.7031 114.837 13.7975 114.764 13.8624ZM119.449 18.6764V16.9997C117.129 16.9997 115.969 17.5238 115.969 18.582C115.969 18.9567 116.108 19.238 116.374 19.4435C116.642 19.65 116.973 19.7434 117.368 19.7434C118.18 19.7434 118.87 19.3874 119.449 18.6764Z"
                      fill="white"
                    />
                    <path
                      d="M26.9612 13.2632L11.1396 0.68008C10.2447 0.052645 9.02488 0.702704 9.02488 1.80809V5.59239C9.02488 6.3526 8.41833 6.96921 7.67054 6.96921H6.8144C5.95443 6.96921 5.25014 7.68518 5.25014 8.55944C5.25014 9.4337 5.95443 10.1497 6.8144 10.1497H7.1317H12.5007C13.3723 10.1497 14.1182 10.8745 14.1066 11.7615C14.095 12.626 13.3955 13.3301 12.5423 13.3301H9.02488H7.1317H1.606C0.734383 13.3301 -0.0114749 14.0549 0.000133718 14.942C0.0117424 15.8064 0.711164 16.5106 1.5644 16.5106H9.02488V16.5145C9.84908 16.5568 10.5108 17.2531 10.5108 18.1008C10.5108 18.9485 9.84908 19.6448 9.02488 19.6871V19.691H8.94752H5.15921C4.30597 19.691 3.60655 20.3952 3.59494 21.2597C3.58334 22.1457 4.32919 22.8715 5.2008 22.8715H7.6696C8.41739 22.8715 9.02394 23.4881 9.02394 24.2483V28.1438C9.02394 29.2413 10.2303 29.8943 11.1261 29.2806L26.9399 17.0406C28.2537 16.1389 28.2643 14.1778 26.9612 13.2632Z"
                      fill="white"
                    />
                  </svg>

                  <Label style={{ marginLeft: 20 }}>
                    <Link href={'https://siu.com.tr'}>
                      <a target="_blank">© 2022 Siu</a>
                    </Link>
                  </Label>
                </div>
                <div className="footer-link-container">
                  <Link href={lang === 'tr' ? `/p/gizlilik-politikasi` : `/p/privacy-policy`}>
                    <a>
                      <FootLabel>{t('common:confidentiality_agreement')}</FootLabel>
                    </a>
                  </Link>
                  <span className="footer-line" style={{ marginLeft: 10, marginRight: 10 }}>
                    |
                  </span>
                  <Link href={lang === 'tr' ? `/p/kullanici-sozlesmesi` : `/p/user-agreement`}>
                    <a>
                      <FootLabel>{t('common:user_agreement')}</FootLabel>
                    </a>
                  </Link>
                  <span className="footer-line" style={{ marginLeft: 10, marginRight: 10 }}>
                    |
                  </span>
                  <Link
                    href={
                      lang === 'tr' ? `/p/mesafeli-satis-sozlesmesi` : `/p/distance-sales-agreement`
                    }
                  >
                    <a>
                      <FootLabel>{t('common:distance_selling_contract')}</FootLabel>
                    </a>
                  </Link>
                  <span className="footer-line" style={{ marginLeft: 10, marginRight: 10 }}>
                    |
                  </span>
                  <Link
                    href={
                      lang === 'tr'
                        ? `/p/satis-iade-ve-degisim`
                        : `/p/sales-returns-and-exchanges-conditions`
                    }
                  >
                    <a>
                      <FootLabel>{t('common:sales_return_exchange')}</FootLabel>
                    </a>
                  </Link>
                </div>
              </FooterDiv>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Footer
