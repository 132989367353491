import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'

function Logo() {
  return (
    <motion.div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <svg
        width="140"
        height="40"
        viewBox="0 0 140 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M55.8293 12.8026L49.3232 18.4716C48.4682 19.2297 48.6822 20.7682 49.5438 21.5116L55.8326 26.8179C56.1619 27.1032 56.5998 26.7281 56.5998 26.1688V13.4472C56.602 12.8834 56.1597 12.5095 55.8293 12.8026Z"
          fill="#7300E5"
        />
        <path
          d="M55.6076 28.9214L46.3598 21.6206C46.081 21.4005 45.6914 21.4027 45.4148 21.6262L43.2417 23.3815C42.9695 23.6185 42.5996 23.7689 42.2803 23.788C41.9565 23.8071 41.6305 23.715 41.3474 23.5028L38.9339 21.7037C38.6584 21.4982 38.2831 21.5038 38.0131 21.716L22.6619 33.8099C21.4206 34.7881 22.0165 36.522 23.4916 36.266L55.2245 30.7452C55.9006 30.6284 56.1629 29.3627 55.6076 28.9214Z"
          fill="#7300E5"
        />
        <path
          d="M55.2761 8.97302L49.9235 8.04203C49.2035 7.91738 48.4637 8.10942 47.8842 8.5721L42.6874 12.7307C42.4218 12.9429 42.0509 12.944 41.7864 12.7307L32.5375 5.34228C32.195 5.06938 31.7944 4.88745 31.3686 4.81445L23.3939 3.42864C21.9232 3.17259 21.3196 4.89194 22.5477 5.87572L41.3539 20.967C41.917 21.4184 42.649 21.415 43.1956 20.9692L44.8298 19.635C44.8869 19.5811 44.9462 19.5329 45.0076 19.4891L55.6635 10.7912C56.2123 10.3442 55.9478 9.08982 55.2761 8.97302Z"
          fill="#7300E5"
        />
        <path
          d="M33.0128 17.9427L16.0614 4.14852C15.1022 3.46123 13.7961 4.17323 13.7961 5.38497V9.53344C13.7961 10.3667 13.1464 11.0428 12.3452 11.0428H11.4276C10.5068 11.0428 9.75169 11.8278 9.75169 12.7857C9.75169 13.7437 10.5068 14.5287 11.4276 14.5287H11.7679H17.52C18.454 14.5287 19.253 15.3226 19.2409 16.2952C19.2289 17.243 18.4793 18.0145 17.565 18.0145H13.7961H11.7679H5.84777C4.91377 18.0145 4.11477 18.8096 4.12684 19.7811C4.13892 20.7289 4.88853 21.5004 5.80277 21.5004H13.7961V21.5049C14.6796 21.551 15.3886 22.3146 15.3886 23.2434C15.3886 24.1732 14.6796 24.9358 13.7961 24.9818V24.9863H13.7127H9.65511C8.74087 24.9863 7.99125 25.7578 7.97918 26.7057C7.96711 27.6771 8.76611 28.4722 9.70011 28.4722H12.3452C13.1464 28.4722 13.7961 29.1482 13.7961 29.9815V34.2513C13.7961 35.454 15.089 36.1705 16.0482 35.4978L32.9908 22.0799C34.3979 21.095 34.4088 18.9455 33.0128 17.9427Z"
          fill="#0095FF"
        />
        <path
          d="M62.7944 15.0543C62.0755 15.0543 61.5212 14.8824 61.1316 14.5377C60.742 14.194 60.5466 13.6931 60.5466 13.035C60.5466 12.3938 60.7409 11.9008 61.1316 11.556C61.5212 11.2124 62.0755 11.0394 62.7944 11.0394C63.544 11.0394 64.1092 11.2113 64.4911 11.556C64.8731 11.9008 65.0651 12.3938 65.0651 13.035C65.0651 13.692 64.8742 14.1929 64.4911 14.5377C64.1092 14.8824 63.544 15.0543 62.7944 15.0543ZM62.7944 28.6901C62.1523 28.6901 61.6551 28.4947 61.3028 28.1038C60.9505 27.713 60.7749 27.1493 60.7749 26.4137V18.9972C60.7749 18.2616 60.9505 17.699 61.3028 17.307C61.6551 16.9162 62.1512 16.7197 62.7944 16.7197C63.4518 16.7197 63.9599 16.9151 64.3199 17.307C64.6788 17.6979 64.8588 18.2616 64.8588 18.9972V26.4137C64.8588 27.1493 64.6832 27.7119 64.3309 28.1038C63.9797 28.4947 63.4671 28.6901 62.7944 28.6901Z"
          fill="#7300E5"
        />
        <path
          d="M69.2402 28.69C68.5981 28.69 68.1009 28.5103 67.7486 28.1499C67.3963 27.7894 67.2207 27.266 67.2207 26.5776V18.8085C67.2207 18.1358 67.393 17.6192 67.7365 17.2599C68.0801 16.8994 68.574 16.7197 69.216 16.7197C69.8581 16.7197 70.3476 16.8994 70.6845 17.2599C71.0215 17.6203 71.1894 18.1358 71.1894 18.8085V19.9113L70.937 18.9489C71.3035 18.2133 71.8282 17.6507 72.5086 17.2587C73.1891 16.8668 73.9651 16.6714 74.8365 16.6714C75.7387 16.6714 76.4806 16.8477 77.0612 17.1992C77.6429 17.5518 78.0786 18.0988 78.3684 18.8422C78.6592 19.5856 78.8041 20.52 78.8041 21.6464V26.5754C78.8041 27.2638 78.6285 27.7882 78.2762 28.1476C77.9239 28.507 77.4201 28.6878 76.7627 28.6878C76.1052 28.6878 75.6004 28.5081 75.2481 28.1476C74.8958 27.7871 74.7202 27.2638 74.7202 26.5754V21.8115C74.7202 21.1073 74.6016 20.6065 74.3646 20.3089C74.1275 20.0113 73.7796 19.863 73.3208 19.863C72.7095 19.863 72.22 20.0629 71.8523 20.4616C71.4857 20.8603 71.3013 21.3971 71.3013 22.0698V26.5765C71.3046 27.9859 70.6165 28.69 69.2402 28.69Z"
          fill="#7300E5"
        />
        <path
          d="M86.007 28.6901C85.4714 28.6901 85.0171 28.553 84.6417 28.279C84.2664 28.005 83.9569 27.5951 83.7132 27.0471L80.4327 19.3723C80.2494 18.9029 80.1879 18.4649 80.2494 18.0584C80.3108 17.6518 80.5051 17.3273 80.8344 17.0847C81.1625 16.8421 81.618 16.7208 82.1997 16.7208C82.6892 16.7208 83.0832 16.8343 83.3806 17.0611C83.6792 17.288 83.9503 17.7304 84.195 18.3874L86.5119 24.4899H85.7316L88.1626 18.3638C88.4073 17.7226 88.6828 17.288 88.9879 17.0611C89.2942 16.8343 89.7145 16.7208 90.2501 16.7208C90.7089 16.7208 91.0798 16.8421 91.3619 17.0847C91.644 17.3273 91.8174 17.6518 91.8778 18.0584C91.9381 18.4649 91.8624 18.9029 91.6484 19.3723L88.3009 27.0707C88.0715 27.6187 87.7697 28.0252 87.3943 28.2914C87.0201 28.5575 86.558 28.6901 86.007 28.6901Z"
          fill="#0095FF"
        />
        <path
          d="M95.458 15.0543C94.7391 15.0543 94.1848 14.8824 93.7952 14.5377C93.4056 14.194 93.2102 13.6931 93.2102 13.035C93.2102 12.3938 93.4045 11.9008 93.7952 11.556C94.1848 11.2124 94.7391 11.0394 95.458 11.0394C96.2076 11.0394 96.7728 11.2113 97.1548 11.556C97.5367 11.9008 97.7288 12.3938 97.7288 13.035C97.7288 13.692 97.5378 14.1929 97.1548 14.5377C96.7728 14.8824 96.2065 15.0543 95.458 15.0543ZM95.458 28.6901C94.8159 28.6901 94.3187 28.4947 93.9664 28.1038C93.6141 27.713 93.4385 27.1493 93.4385 26.4137V18.9972C93.4385 18.2616 93.6141 17.699 93.9664 17.307C94.3187 16.9162 94.8148 16.7197 95.458 16.7197C96.1154 16.7197 96.6235 16.9151 96.9835 17.307C97.3424 17.6979 97.5224 18.2616 97.5224 18.9972V26.4137C97.5224 27.1493 97.3468 27.7119 96.9945 28.1038C96.6422 28.4947 96.1308 28.6901 95.458 28.6901Z"
          fill="#0095FF"
        />
        <path
          d="M104.289 28.7372C103.325 28.7372 102.472 28.4946 101.731 28.0095C100.989 27.5243 100.409 26.828 99.9885 25.9206C99.5681 25.0132 99.3574 23.9407 99.3574 22.7054C99.3574 21.4386 99.5681 20.3583 99.9885 19.4666C100.409 18.5749 100.989 17.8865 101.731 17.4013C102.473 16.9162 103.326 16.6736 104.289 16.6736C105.129 16.6736 105.887 16.8769 106.559 17.2834C107.232 17.69 107.683 18.2144 107.913 18.8557H107.66V13.8335C107.66 13.1451 107.836 12.6206 108.188 12.2612C108.54 11.9019 109.044 11.7211 109.702 11.7211C110.344 11.7211 110.844 11.9008 111.204 12.2612C111.564 12.6217 111.743 13.1451 111.743 13.8335V26.5776C111.743 27.266 111.571 27.7905 111.227 28.1498C110.883 28.5092 110.382 28.69 109.725 28.69C109.083 28.69 108.585 28.5103 108.234 28.1498C107.882 27.7894 107.706 27.266 107.706 26.5776V25.2401L107.959 26.3901C107.745 27.0942 107.297 27.6613 106.618 28.0914C105.936 28.5227 105.16 28.7372 104.289 28.7372ZM105.596 25.6623C106.008 25.6623 106.375 25.5568 106.697 25.3456C107.018 25.1345 107.266 24.8133 107.442 24.3832C107.617 23.9531 107.705 23.3938 107.705 22.7054C107.705 21.6576 107.507 20.9018 107.109 20.4403C106.712 19.9787 106.207 19.7473 105.596 19.7473C105.182 19.7473 104.815 19.8495 104.495 20.0528C104.173 20.2561 103.921 20.5728 103.738 21.0029C103.554 21.433 103.462 22.0001 103.462 22.7043C103.462 23.7375 103.661 24.4876 104.058 24.9582C104.457 25.4287 104.969 25.6623 105.596 25.6623Z"
          fill="#0095FF"
        />
        <path
          d="M115.344 28.4789C114.931 28.4789 114.595 28.3733 114.334 28.1622C114.074 27.9511 113.899 27.6815 113.807 27.3525C113.714 27.0234 113.719 26.6686 113.819 26.2845C113.917 25.9015 114.113 25.5455 114.404 25.2165L119.725 19.0668V20.0056H115.184C114.694 20.0056 114.32 19.8731 114.06 19.607C113.8 19.3408 113.67 18.9579 113.67 18.457C113.67 17.9561 113.8 17.5765 114.06 17.3182C114.32 17.0599 114.694 16.9308 115.184 16.9308H121.676C122.135 16.9308 122.509 17.0364 122.8 17.2475C123.091 17.4586 123.286 17.7293 123.385 18.0572C123.483 18.3862 123.488 18.7377 123.396 19.1128C123.303 19.4879 123.112 19.8405 122.823 20.1685L117.364 26.4821V25.4029H122.25C123.274 25.4029 123.786 25.9116 123.786 26.928C123.786 27.4288 123.656 27.8118 123.397 28.078C123.137 28.3441 122.755 28.4766 122.25 28.4766H115.344V28.4789Z"
          fill="#0095FF"
        />
        <path
          d="M129.084 28.7372C128.213 28.7372 127.444 28.5732 126.779 28.2442C126.114 27.9151 125.594 27.4659 125.22 26.8943C124.844 26.3227 124.658 25.6702 124.658 24.9346C124.658 24.0901 124.872 23.4253 125.3 22.9401C125.728 22.455 126.416 22.1068 127.364 21.8957C128.312 21.6846 129.574 21.579 131.15 21.579H132.412V23.5511H131.15C130.554 23.5511 130.06 23.5949 129.67 23.6802C129.281 23.7667 128.993 23.8992 128.81 24.0789C128.626 24.2586 128.534 24.4899 128.534 24.7707C128.534 25.1312 128.66 25.4276 128.913 25.6623C129.165 25.8971 129.529 26.0139 130.003 26.0139C130.385 26.0139 130.725 25.9274 131.023 25.7556C131.322 25.5837 131.562 25.3445 131.746 25.0402C131.929 24.7347 132.021 24.3787 132.021 23.9722V21.25C132.021 20.6862 131.879 20.2875 131.596 20.0528C131.313 19.8181 130.828 19.7002 130.14 19.7002C129.773 19.7002 129.36 19.744 128.902 19.8293C128.443 19.9158 127.931 20.0674 127.365 20.2864C126.953 20.4425 126.6 20.4672 126.309 20.3572C126.019 20.2471 125.794 20.0596 125.632 19.7934C125.472 19.5272 125.395 19.2375 125.403 18.9253C125.411 18.612 125.511 18.3155 125.701 18.0336C125.892 17.7517 126.187 17.5327 126.585 17.3766C127.319 17.0948 127.995 16.9072 128.615 16.8129C129.234 16.7186 129.804 16.6714 130.324 16.6714C131.578 16.6714 132.614 16.8556 133.432 17.2228C134.25 17.59 134.863 18.1538 135.268 18.913C135.673 19.6721 135.876 20.6458 135.876 21.8351V26.5765C135.876 27.2649 135.715 27.7894 135.394 28.1487C135.072 28.5081 134.606 28.6889 133.994 28.6889C133.368 28.6889 132.889 28.5092 132.561 28.1487C132.233 27.7882 132.068 27.2649 132.068 26.5765V25.9667L132.205 26.2957C132.129 26.7966 131.953 27.2267 131.678 27.5872C131.402 27.9477 131.043 28.2285 130.6 28.4317C130.155 28.635 129.65 28.7372 129.084 28.7372Z"
          fill="#0095FF"
        />
      </svg>
    </motion.div>
  )
}

export default Logo
