import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Layout, Menu, Row, Col, Popover } from 'antd'
import { UnorderedListOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'

import dynamic from 'next/dynamic'

import FooterUI from './Footer'
import { langConverterReversed, localeToLang, withLocale } from '../../../helpers/urlhelpers'
import { getMeInfo } from '../../../redux/Actions/AuthenticationAction'
import Logo from './Logo'
import { LanguageContext } from '../../../contexts/LanguageContext'

const { SubMenu } = Menu

const { Content, Footer, Header } = Layout

const RESPONSIVE_MOBILE = 768

function LayoutUI({ children }) {
  const { changeLanguage } = useContext(LanguageContext)
  const router = useRouter()
  const { pathname, asPath, query, locale } = useRouter()
  // var pusher = router.push;
  // router.push = (url, as, options) => {
  //   console.log(locale);
  //   if (locale == "tr") {
  //     url = "/tr/online-davetiye";
  //   }
  //   pusher(url, as, options);
  // };
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userInfo = useSelector((state) => state.Authentication.userInfo)

  const [isMobile, setIsMobile] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const menuMode = isMobile ? 'inline' : 'horizontal'

  const onWindowResize = useCallback(() => {
    const newIsMobile = window.innerWidth < RESPONSIVE_MOBILE
    if (isMobile !== newIsMobile) {
      setIsMobile(newIsMobile)
    }
  }, [isMobile])

  useEffect(() => {
    if (localStorage.getItem('authToken')) dispatch(getMeInfo())
    window.addEventListener('resize', onWindowResize)
    onWindowResize()
    return () => {
      // unsubscribe event
      window.removeEventListener('resize', onWindowResize)
    }
  }, [dispatch, onWindowResize])

  useEffect(() => {
    if (isMenuVisible) {
      setIsMenuVisible(false)
    }
  }, [router.asPath])

  const handleShowMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  const menu = (
    <Menu id="nav" mode={menuMode} selectedKeys={router.pathname} disabledOverflow>
      <Menu.Item style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Link href="#" locale="en">
          <a
            style={{ display: 'flex' }}
            onClick={() => {
              changeLanguage('en')
            }}
          >
            <Image
              src="/images/enFlag.png"
              width={40}
              height={30}
              alt="English Flag"
              title="English"
            />
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Link href="#" locale="tr">
          <a
            style={{ display: 'flex' }}
            onClick={() => {
              changeLanguage('tr')
            }}
          >
            <Image
              src="/images/trFlag.png"
              width={40}
              height={30}
              alt="Turkish Flag"
              title="Türkçe"
            />
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => router.push(`/paketler`)} key="/paketler">
        {t('common:packages')}
      </Menu.Item>
      <Menu.Item onClick={() => router.push(`/yardim-merkezi`)} key="/yardim-merkezi">
        {t('common:help-center')}
      </Menu.Item>
      <Menu.Item onClick={() => router.push(`/blog`)} key="/blog">
        {t('common:blog')}
      </Menu.Item>
      {Object.keys(userInfo).length > 0 ? (
        <SubMenu key="SubMenu" title={userInfo.name}>
          {userInfo.agent_id && (
            <>
              <Menu.Item onClick={() => router.push(`/solution-partner`)} key="/solution-partner">
                {t('common:affiliate_panel')}
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item onClick={() => router.push(`/m/events`)} key="/m/events">
            {t('common:myevents')}
          </Menu.Item>
          <Menu.Item onClick={() => router.push(`/my-credits`)} key="/my_credits">
            {t('common:my_credits')}
          </Menu.Item>
          <Menu.Item onClick={() => router.push(`/account`)} key="/account">
            {t('common:my_account')}
          </Menu.Item>
          {/* <Menu.Item onClick={() => router.push(`/integration`)} key="/integration">
            {t('common:integrations')}
          </Menu.Item> */}
          <Menu.Divider />
          <Menu.Item
            onClick={() => {
              localStorage.removeItem('authToken')
              if (router.pathname !== '/blog') router.push('/login')
              dispatch({ type: 'LOGOUT' })
            }}
            danger
            key="setting:4"
          >
            {t('common:logout')}
          </Menu.Item>
        </SubMenu>
      ) : (
        <Menu.Item onClick={() => router.push(`/login`)} key="/login">
          {t('common:login')}
        </Menu.Item>
      )}
    </Menu>
  )

  const rightMenu = () =>
    isMobile ? (
      <Popover
        content={menu}
        trigger="click"
        visible={isMenuVisible}
        placement="bottomRight"
        arrowPointAtCenter
      >
        <UnorderedListOutlined size={40} onClick={handleShowMenu} />
      </Popover>
    ) : (
      menu
    )

  const renderHeader = () => {
    if (
      pathname == '/v/[slug]' ||
      pathname == '/preview-invitation/[slug]' ||
      asPath === '/quick-invitation/?mobile=true'
    ) {
      return null
    } else {
      return (
        <Row
          style={{
            padding: isMobile && '1rem',
            background: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Col
            flex={!isMobile && '300px'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link href="/">
              <a>
                <Logo />
              </a>
            </Link>
            {/* <ReactFlagsSelect
              className={styles.flagsSelect}
              countries={['US', 'TR']}
              customLabels={{
                US: 'English',
                TR: 'Türkçe',
              }}
              showSelectedLabel={false}
              fullWidth={false}
              selectedSize={15}
              selected={selectedCountry}
              onSelect={(code) => {
                setSelectedCountry(code)
                // console.log(code, pathname, query);
                // let n = withLocale(pathname, langConverterReversed(code));
                // console.log(n, pathname);
                // if (n !== pathname) {
                //   console.log("bum");
                //   router.push({ pathname: n, query }, asPath, {
                //     locale: langConverterReversed(code),
                //   });
                // } else
                router.push({ pathname, query }, asPath, {
                  locale: langConverterReversed(code),
                })
              }}
            /> */}
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {rightMenu()}
          </Col>
        </Row>
      )
    }
  }

  const renderFooter = () => {
    if (
      pathname == '/v/[slug]' ||
      pathname == '/preview-invitation/[slug]' ||
      pathname == '/m/events' ||
      pathname == '/my-credits' ||
      asPath === '/quick-invitation/?mobile=true'
    ) {
      return null
    } else {
      return (
        <Footer style={{ padding: 0 }}>
          <FooterUI />
        </Footer>
      )
    }
  }

  return (
    <Layout>
      {renderHeader()}
      <Content>{children}</Content>
      {renderFooter()}
    </Layout>
  )
}

export default LayoutUI
