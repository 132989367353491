const en = [];
const tr = [
  {
    source: "/template",
    destination: "/tr/online-davetiye/",
  },
];

const localizedPaths = { en: en, tr: tr };

export function langConverter(lang) {
  if (lang.toLowerCase() == "en") return "en-gb";
  return lang.toLowerCase();
}

export function langConverterReversed(lang) {
  if (lang.toLowerCase() == "en-gb") return "en";
  else if (lang.toLowerCase() == "us") return "en";
  return lang.toLowerCase();
}

export function localeToLang(locale) {
  if (locale.toLowerCase() == "en") return "US";
  else if (locale.toLowerCase() == "tr") return "TR";
  return "US";
}

export function withLocale(uri, locale = "en") {
  let t = localizedPaths[locale].find((el) => el.source == uri);
  return t ? t.destination : uri;
}
