import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #330066;
  a {
    color: #cecee1 !important;

    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    @media (max-width: 1670px) {
      font-size: 15px;
    }
    @media (max-width: 1310px) {
      font-size: 15px;
    }
  }
  .footer-row-container {
    @media (max-width: 780px) {
      padding-left: 20px;
    }
    .footer-container-style {
      width: 100%;
      padding: 0px 72px;
      max-width: 1232px;
      @media (max-width: 1200px) {
        padding: 0px 10px;
      }
    }
  }
  .join-us-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1200px) {
      justify-content: flex-start;
    }
  }
`

export const Label = styled.h6`
  font-family: Poppins;
  color: #ffffff;
  display: block;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 5px;
  @media (max-width: 1200px) {
    font-size: 15px;
  }
`

export const FootLabel = styled.span`
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
`

export const Span = styled.h5`
  font-family: Poppins;
  font-weight: 700;
  font-size: 26px;
  color: #ffffff;
  margin-bottom: 10px;
`

export const FooterDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 72px;
  margin-top: 24px;
  margin-bottom: 24px;
  @media (max-width: 1200px) {
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 10px;
  }
  .footer-link-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    .footer-line {
      color: #ffffff;
    }
    @media (max-width: 1200px) {
      margin-top: 10px;
      flex-direction: column;
      .footer-line {
        display: none;
      }
    }
  }
`

export default Wrapper
